import * as React from "react"

class EnquiryForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formSubmitted: false,
      message: ""

    }
    
  }
  componentDidMount() {
    document.querySelector("#contact").addEventListener("submit", this.handleSubmit)
  }
  

  handleSubmit = e => {
    e.preventDefault()
    let myForm = document.getElementById("contact")
    let formData = new FormData(myForm)
    if(formData.get('Full Name') && formData.get('Email')){
      console.log('test fetch')
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData).toString(),
      })
      .then(() => {
        const form = document.querySelector("#contact")
        form.classList.add("fade-out")
        setTimeout(() => {
          this.setState({ formSubmitted: true },()=>{
            const thankYou = document.querySelector("#thank-you")
            setTimeout(()=>{
              thankYou.classList.add("fade-in-thank-you")
            },100)
           
          })
        }, 1250)
      })
    .catch(error => console.error(error))
    document.querySelector("#contact").addEventListener("submit", this.handleSubmit);
    }
  }

  render() {
    const formSubmitted = this.state.formSubmitted

    const closeMenu = () => {
      document.body.classList.remove('menu-open');
    }
    
    if(!formSubmitted){
    return (
      <div className="form text-center">
        <form method="POST" data-netlify="true" id="contact" className="contact--form">
            <input type="hidden" name="form-name" value="contact" />
            <div className='flex m-wrap'>
              <div className='w-50 m-100 flex mb20'>
                <input required={true} name="Full Name" type="text" className="p20 m-m0 w-100 input mr10" placeholder="Full Name*"/>
              </div>
              <div className='w-50 m-100 flex mb20'>
                <input required={true} name="Email" type="text" className="p20 m-m0 w-100 input ml10" placeholder="Email Address*" />
              </div>
            </div> 
            <div className='flex m-wrap'>
              <div className='w-50 flex mb20 m-hide'>
                <input required={false} name="Social" type="text" className="p20 w-100 input mr10" placeholder="Social Handle (@)" />
              </div>
              <div className='w-50 m-100 flex mb20'>
               <input required={false} name="website" type="text" className="p20 w-100 m-m0 input ml10" placeholder="Existing Website (www)" />
              </div>
            </div>
            
            <div className='text-area flex'>
              <textarea onChange={(e) => this.setState({message:e.target.value})} value={this.state.message} className="textarea input p20 w-100" name="message" placeholder='Message...' type="text" id="message" />
            </div>

            <div className="submit mt40 mb20 m-mt20">
              <button type="submit" className="button input">Submit</button>
            </div>
          </form>
      </div>
    )}else{
      return (
      <div className="form-section mt40 mb20 text-center" id="thank-you">
        <p className='m0'>Thanks for reaching out!<br/> I'll be in touch soon :)</p>
        <p className='button mt40 m0' role='presentation' onClick={closeMenu}>Close</p>
      </div>
      )
    }
  }
}

export default EnquiryForm
