import * as React from "react"
import { Link } from "gatsby"
import Form  from "./form"
import Newsletter  from "./newsletter"
import { useStaticQuery, graphql } from "gatsby"

const Header = ({ siteTitle }) => {
  const openEnquiry = (e) => {
    document.body.classList.add('menu-open')
    var title = e.target.getAttribute('data-title');
    if(title) {
      document.querySelector('.popup--title').innerHTML = title;
    }
  }
  return (
  <>
    <header className='p30 flex masthead align-center'>
      <Link to="/" className='link'>
        <div className='logo--main'/>
      </Link>
      <div className='mla flex gap-20 m-gap-10'>  
        <a className='m0 pointer' href='https://calendly.com/carl-beaverson/intro' target='_blank' rel='noreferrer'>Book a Call</a>
        <p className='m0 pointer m-hide' role='presentation' data-title='Get In Touch' onClick={openEnquiry}>Get in Touch</p>
      </div>
    </header>
    <Overlay />
    <p className='fixed--button button m0' role='presentation' onClick={openEnquiry}>Get In Touch</p>
  </>
  )
}

export default Header

const Overlay = () => {
  var data  = useStaticQuery(
    graphql`
      query Footer {
        header:datoCmsHomepage {
          contactForm
          newsletter
        }
      }
    `
  )
  const closeMenu = () => {
    document.body.classList.remove('menu-open')
    document.body.classList.remove('newsletter-open')
  }
  return (
    <>
      <div className='overlay--modal newsletter'>
        <div className='close' role='presentation' onClick={closeMenu}/>
        <div className='text-center mb40'>
          <p className='m0 mb20 large dark mt40 m-mt20'>Newsletter Signup</p>
          <div className='content max-400 ma'>
            {data.header.newsletter} 
          </div>
        </div>
        <Newsletter />
      </div>
      <div className='overlay--modal form'>
        <div className='close' role='presentation' onClick={closeMenu}/>
        <div className='text-center mb40 m-mb20'>
          <p className='m0 mb20 large dark mt40 popup--title m-mt20'>Get In Touch</p>
          <div className='content max-650 ma'>
            {data.header.contactForm}
          </div>
        </div>
        <Form />
      </div>
      <div className='overlay--bg' role='presentation' onClick={closeMenu}/>
    </>
  )
}